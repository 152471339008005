<template>
  <div class="style">
    <div class="title"></div>
    <div class="crumb"></div>
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input-search
            class="search-input"
            placeholder="请输入您要查找的内容"
            v-model="searchName"
            @search="onSearch"
          />
        </div>
        <div class="operation">
          <a-button class="add" type="primary">添加新的样式</a-button>
          <a-button class="delete" type="danger">删除所选样式</a-button>
        </div>
      </div>
      <a-card class="table">
        <a-table :columns="columns" :data-source="showData">
          <span slot="name" slot-scope="text, record">
            <a>{{ record.name }}</a>
          </span>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import http from "@/config/axios.config";
const columns = [
  {
    title: "样式名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" }
  }
];
export default {
  data() {
    return {
      searchName: null,
      data: [],
      showData: [],
      columns,
    };
  },
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showData = this.data.filter((p) => p.name.indexOf(this.searchName) !== -1 );
      } else {
        this.refreshStyles();
      }
    },
    refreshStyles(){
      http({
      url: `/rest/styles`,
      method: "get",
    }).then((res) => {
      this.data = res.data.styles.style.map(item => {
        item.key = item.name;
        return item;
      });
      this.showData = this.data;
    });
    },
  },
  mounted() {
    this.refreshStyles();
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>
<style>
.style .search-input .ant-input{
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.style .search-input .ant-input-suffix{
  font-size: 18px;
}
</style>